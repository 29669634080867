<template>
  <v-container fill-height fluid grid-list-xl>
    <v-layout justify-center wrap>
      <v-flex xs12 md12 v-if="!isNew">
        <v-card style="background-color: white" color="#31708E" title="Manage Contacts">
          <v-data-table :loading="loading" :headers="headers" :items="contacts">
            <template slot="headerCell" slot-scope="{ header }">
              <span class="font-weight-light text-warning text--darken-3" v-text="header.text" />
            </template>
            <template slot="item" slot-scope="{ item }">
              <tr>
                <td class="text-xs-left">{{ item.firstName }}</td>
                <td class="text-xs-left">{{ item.lastName }}</td>
                <td class="text-xs-left">{{ item.email }}</td>
                <td class="text-xs-left">{{ item.telephone }}</td>
                <td style="width: 250px">
                  <v-select :items="actions" v-model="item.actions" @change="action(item)" label="Choose Action"></v-select>
                </td>
              </tr>
            </template>
          </v-data-table>

          <v-dialog v-model="showRemovecontactDialog" width="500" scrollable>
            <v-card :loading="doProccess" :disabled="doProccess">
              <v-card-title class="headline grey lighten-2" primary-title
                >{{ contactAction }}
                {{ this.activeitem ? this.activeitem.firstName + " " + this.activeitem.lastName : "Contact" }}</v-card-title
              >
              <v-card-text>Please confirm contact {{ lower(contactAction) }} action.</v-card-text>
              <v-divider></v-divider>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="danger" :loading="doProccess" @click="doAction(activeitem)">{{ upper(contactAction) }}</v-btn>
                <v-btn color="secondary" text @click="cancelAction(contactAction)">Cancel</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-flex xs12 text-xs-right>
            <v-btn class="mx-2" @click="newAccount()" fab dark color="#5085A5">
              <v-icon dark>mdi-plus</v-icon>
            </v-btn>
          </v-flex>
        </v-card>
      </v-flex>

      <v-slide-y-transition mode="out-in">
        <v-flex xs12 md12 v-if="isNew">
          <v-card
            style="background-color: white"
            :title="contactAction == 'Reset Password' ? 'Reset Password ' : contactAction + ' Contact '"
            text="Complete your Contact information"
            :loading="doProccess"
            :disabled="doProccess"
          >
            <v-card-text>
              <v-form>
                <v-container py-0>
                  <v-layout wrap>
                    <v-flex xs12 md6>
                      <v-text-field
                        id="firstname"
                        label="First Name"
                        v-model="newContact.firstName"
                        v-if="contactAction === 'Edit' || (isNew && contactAction === '')"
                        class="purple-input"
                        :error-messages="firstnameErrors"
                        @blur="$v.newContact.firstName.$touch()"
                      />
                      <v-text-field label="First Name" v-model="newContact.firstName" v-else :disabled="'disabled'" class="purple-input" />
                    </v-flex>
                    <v-flex xs12 md6>
                      <v-text-field
                        label="Last Name"
                        id="lastname"
                        v-if="contactAction === 'Edit' || (isNew && contactAction === '')"
                        v-model="newContact.lastName"
                        class="purple-input"
                        :error-messages="lastnameErrors"
                        @blur="$v.newContact.lastName.$touch()"
                      />
                      <v-text-field label="Last Name" :disabled="'disabled'" v-model="newContact.lastName" v-else class="purple-input" />
                    </v-flex>
                    <v-flex xs12 md6>
                      <v-text-field
                        label="Email Address"
                        v-if="contactAction === 'Edit' || (isNew && contactAction === '')"
                        v-model="newContact.email"
                        class="purple-input"
                        id="emailaddress"
                        :error-messages="emailErrors"
                        @blur="$v.newContact.email.$touch()"
                      />
                      <v-text-field label="Email Address" v-else disabled="disabled" v-model="newContact.email" class="purple-input" />
                    </v-flex>
                    <v-flex xs12 md6>
                      <v-text-field
                        label="Telephone"
                        v-if="contactAction === 'Edit' || (isNew && contactAction === '')"
                        v-model="newContact.telephone"
                        class="purple-input"
                        id="telephone"
                        required
                        :error-messages="phoneErrors"
                        @blur="$v.newContact.telephone.$touch()"
                      />
                      <v-text-field label="Telephone" :disabled="'disabled'" v-model="newContact.telephone" v-else class="purple-input" />
                    </v-flex>

                    <v-flex xs12 md6>
                      <v-select
                        v-model="groupSelected"
                        :items="grouplist"
                        :multiple="true"
                        id="contactGroups"
                        label="Contact Groups"
                        v-if="contactAction === 'Edit' || (isNew && contactAction === '')"
                        item-text="gname"
                        item-value="id"
                      >
                      </v-select>
                      <v-select v-model="groupSelected" :items="grouplist" label="Contact Groups" :disabled="'disabled'" v-else> </v-select>
                    </v-flex>

                    <v-flex xs12 md6>
                      <DateDropdown
                        :default="newContact.birthDate"
                        :min="(new Date().getFullYear() - 100).toString()"
                        :max="(new Date().getFullYear() - 1).toString()"
                        v-model="newContact.birthDate"
                      />
                    </v-flex>

                    <v-flex xs12 text-xs-right>
                      <v-btn class="mx-0 mr-2 font-weight-light" @click="cancelAction()" color="primary">Cancel</v-btn>
                      <v-btn class="mx-0 font-weight-light" :loading="doProccess" @click="create()" color="#5085A5">{{
                        contactAction == "Edit"
                          ? "Update contact "
                          : contactAction == "Reset Password"
                          ? "Reset Password"
                          : "Create contact "
                      }}</v-btn>
                    </v-flex>
                  </v-layout>
                </v-container>
              </v-form>
            </v-card-text>
          </v-card>
        </v-flex>
      </v-slide-y-transition>
    </v-layout>
  </v-container>
</template>

<script>
import contactService from "@/services/contactService";
import contactGroupService from "@/services/contactGroupService";
import { required, email } from "vuelidate/lib/validators";
import Vue from "vue";
import DateDropdown from "@/components/DatePicker.vue";
import IdGenerator from "../../../shared/extensions/IdGenerator";
import { isValidPhoneNumber } from "libphonenumber-js";
const validatePhone = (value) => (value ? isValidPhoneNumber(value, "AU") : true);

export default {
  components: {
    DateDropdown,
  },
  data() {
    return {
      loading: false,
      actions: ["Edit", "Remove"],
      contactAction: "",
      showRemovecontactDialog: false,
      doProccess: false,
      isNew: false,
      activeitem: null,
      grouplist: [],
      groupSelected: [],
      newContact: {
        id: null,
        companyId: null,
        firstName: "",
        lastName: "",
        email: "",
        telephone: "",
        birthDate: "",
      },
      rules: {
        required: (value) => !!value || "Required.",
        min: (v) => v.length >= 8 || "Min 8 characters",
      },
      headers: [
        {
          sortable: false,
          text: "First Name",
          value: "firstName",
        },
        {
          sortable: false,
          text: "Last Name",
          value: "lastName",
          align: "left",
        },
        {
          sortable: false,
          text: "Email",
          value: "email",
          align: "left",
        },
        {
          sortable: false,
          text: "Telephone",
          value: "telephone",
          align: "left",
        },
        {
          sortable: false,
          text: "Actions",
          value: "actions",
          align: "left",
        },
      ],
      contacts: [],
    };
  },
  validations: {
    newContact: {
      firstName: {
        required,
      },
      email: {
        email,
      },
      telephone: {
        phoneValid: validatePhone,
      },
      lastName: {
        required,
      },
    },
  },
  mounted() {
    this.loadContacts();
    this.loadGroups();
  },
  methods: {
    newAccount() {
      this.newContact = {
        firstName: "",
        lastName: "",
        email: "",
        telephone: "",
        birthDate: "",
        id: null,
        companyId: null,
      };
      this.groupSelected = [];
      this.isNew = true;
    },
    async create() {
      try {
        this.doProccess = true;
        this.$v.$touch();
        if (this.$v.$invalid) {
          return;
        }
        var da = this.newContact.birthDate.split("/");
        var data = {
          firstName: this.newContact.firstName,
          lastName: this.newContact.lastName,
          email: this.newContact.email,
          telephone: this.newContact.telephone,
          birthDate: new Date(da[2], da[0] - 1, da[1]),
          id: this.newContact.id ? this.newContact.id : null,
          companyId: this.newContact.companyId ? this.newContact.companyId : null,
        };

        if (!data.id) {
          Vue.set(data, "companyId", this.companyId);
          const docs = await contactService.checkExist(data);
          if (docs && docs.size > 0) {
            this.$notification.showError("Contact with this email already exist");
            this.doProccess = false;
            return;
          }
          data.id = IdGenerator.newId();
          await contactService.updateContactInfo(data);
          if (this.groupSelected.length > 0) {
            for (const groupId of this.groupSelected) {
              await contactGroupService.addContactToGroup(groupId, data.id);
            }
          }
          this.$notification.showSuccess("The contact saved successfully.");
          this.loadContacts();
          this.doProccess = false;
          this.isNew = false;
          this.contactAction = "";
        } else {
          const docs = await contactService.checkEmailExist(data);
          if (docs && docs.size > 0) {
            this.$notification.showError("Contact with this email already exist");
            this.doProccess = false;
            return;
          }
          await contactService.updateContactInfo(data);
          await contactGroupService.removeContactFromGroups(this.companyId, data.id);
          if (this.groupSelected.length > 0) {
            for (const groupId of this.groupSelected) {
              await contactGroupService.addContactToGroup(groupId, data.id);
            }
          }
          this.$notification.showSuccess("The contact saved successfully.");
          this.loadContacts();
          this.doProccess = false;
          this.isNew = false;
          this.contactAction = "";
        }
      } catch (error) {
        this.$notification.showError(error.message);
      } finally {
        this.doProccess = false;
      }
    },
    async loadContacts() {
      try {
        this.loading = true;
        this.contacts = [];
        const docs = await contactService.getContacts(this.companyId);
        docs.forEach((doc) => {
          var u = doc.data();
          u.id = doc.id;
          u.actions = "";
          if (u.birthDate) {
            u.birthDate = new Date(u.birthDate.toDate()).toLocaleDateString();
          } else {
            u.birthDate = new Date((new Date().getFullYear() - 1).toString()).toLocaleDateString();
          }
          this.contacts.push(u);
        });
        this.$forceUpdate();
      } catch (error) {
        this.$notification.showError(error.message);
      } finally {
        this.loading = false;
      }
    },
    async loadGroups() {
      this.grouplist = [];
      const docs = await contactGroupService.getContactGroups(this.companyId);
      docs.forEach((doc) => {
        this.grouplist.push({ id: doc.id, gname: doc.data().groupName });
      });
      this.$forceUpdate();
    },
    async action(item) {
      this.activeitem = item;
      if (item.actions === "Remove") {
        this.contactAction = "Remove";
        this.showRemovecontactDialog = true;
      }
      if (item.actions === "Edit" || item.actions === "Reset Password") {
        this.contactAction = "Edit";
        this.isNew = true;
        this.newContact = item;
        this.groupSelected = await contactGroupService.getContactGroupsOfContact(this.companyId, item.id);
      }
    },
    lower(item) {
      return (item + " ").toLowerCase();
    },
    async doAction(contact) {
      if (contact.actions == "Remove") {
        try {
          this.doProccess = true;
          await contactGroupService.removeContactFromGroups(this.companyId, contact.id);
          await contactService.removeContact(contact);
          this.$notification.showSuccess("The contact removed successfully.");
          this.contactAction = "";
          this.loadContacts();
          this.showRemovecontactDialog = false;
        } catch (error) {
          this.$notification.showError(error.message);
        } finally {
          this.doProccess = false;
        }
      }
    },
    cancelAction(contactAction) {
      this.loadContacts();
      this.contactAction = "";

      if (this.activeitem) this.activeitem.actions = "";

      this.isNew = false;
      this.showRemovecontactDialog = false;
    },
    upper(item) {
      return (item + " ").toUpperCase();
    },
  },
  watch: {
    showRemovecontactDialog: function (val) {
      if (!val) {
        this.activeitem.actions = "";
      }
    },
  },
  computed: {
    companyId() {
      return this.$store.getters.companyId;
    },
    userId() {
      return this.$store.getters.userId;
    },
    emailErrors() {
      const errors = [];
      if (!this.$v.newContact.email.$dirty) return errors;
      !this.$v.newContact.email.email && errors.push("Must be a valid e-mail");
      return errors;
    },
    phoneErrors() {
      const errors = [];
      if (!this.$v.newContact.telephone.$dirty) return errors;
      !this.$v.newContact.telephone.phoneValid && errors.push("Must be a valid phone number");
      return errors;
    },
    firstnameErrors() {
      const errors = [];
      if (!this.$v.newContact.firstName.$dirty) return errors;
      !this.$v.newContact.firstName.required && errors.push("First name is required");
      return errors;
    },
    lastnameErrors() {
      const errors = [];
      if (!this.$v.newContact.lastName.$dirty) return errors;
      !this.$v.newContact.lastName.required && errors.push("Last name is required");
      return errors;
    },
  },
};
</script>
